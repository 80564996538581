var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vx-card", [
    _c("div", { staticClass: "grid grid-cols-12 gap-4" }, [
      _c("div", { staticClass: "col-span-12" }, [
        _c("fieldset", { staticClass: "p-2 pt-0 border border-grey" }, [
          _c("legend", { staticClass: "px-1 font-bol" }, [
            _vm._v(" " + _vm._s(_vm.$t("filtros")) + " "),
          ]),
          _c(
            "div",
            { staticClass: "flex items-center p-2 flex-wrap gap-4" },
            [
              _c("select-suggestion", {
                ref: "select_questionnaire",
                class: _vm.questionnaire ? "flex-grow" : "flex-grow required",
                attrs: {
                  max: 20,
                  column: "name,description",
                  model: "ContentQuestionnaire",
                  label: _vm.$t("Questionnaire"),
                  appendClearOption: true,
                  placeholderText: "Digite o nome do questionário",
                },
                on: { "selected-model": _vm.updateGrid },
                model: {
                  value: _vm.questionnaire,
                  callback: function ($$v) {
                    _vm.questionnaire = $$v
                  },
                  expression: "questionnaire",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.questionnaire,
              expression: "questionnaire",
            },
          ],
          staticClass: "col-span-12",
        },
        [
          _c("Grid", {
            ref: "grid",
            attrs: {
              service: _vm.service,
              route_name: "institutions",
              route_grid_path: "/questionnaire_score/grid",
              hide_actions: true,
              fetchOnMounted: false,
              showExport: true,
              order_column: "users.name",
              column_formats: {
                score: function (value) {
                  var score = !this$1.isEmpty(value) ? value : "0"

                  return parseFloat(score).toFixed(2)
                },
              },
              exportFilename:
                "Relatório Pontuação - " +
                (_vm.questionnaire && _vm.questionnaire.name),
              "request-params": _vm.gridParams,
            },
            scopedSlots: _vm._u([
              {
                key: "filterZone",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "flex items-center" },
                      [
                        _c("vs-checkbox", {
                          attrs: { color: "primary" },
                          model: {
                            value: _vm.gridParams.section_grouped,
                            callback: function ($$v) {
                              _vm.$set(_vm.gridParams, "section_grouped", $$v)
                            },
                            expression: "gridParams.section_grouped",
                          },
                        }),
                        _c(
                          "vs-label",
                          {
                            class: [
                              "text-sm select-none font-bold",
                              {
                                "text-primary": _vm.gridParams.section_grouped,
                              },
                            ],
                          },
                          [_vm._v(_vm._s(_vm.$t("agrupar-por-secao")))]
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }