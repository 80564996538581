<template>
  <vx-card>
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12">
        <fieldset class="p-2 pt-0 border border-grey">
          <legend class="px-1 font-bol"> {{ $t('filtros') }} </legend>
          <div class="flex items-center p-2 flex-wrap gap-4">
            <select-suggestion
              :max="20"
              :class="questionnaire ? 'flex-grow' : 'flex-grow required'"
              @selected-model="updateGrid"
              column="name,description"
              model="ContentQuestionnaire"
              :label="$t('Questionnaire')"
              v-model="questionnaire"
              :appendClearOption="true"
              ref="select_questionnaire"
              placeholderText="Digite o nome do questionário"
            />
          </div>
        </fieldset>
      </div>
      <div class="col-span-12" v-show="questionnaire">
        <Grid
          ref="grid"
          :service="service"
          route_name="institutions"
          route_grid_path="/questionnaire_score/grid"
          :hide_actions="true"
          :fetchOnMounted="false"
          :showExport="true"
          order_column="users.name"
          :column_formats="{
            'score': (value) => {
              const score = !this.isEmpty(value) ? value : '0'

              return parseFloat(score).toFixed(2)
            }
          }"
          :exportFilename="`Relatório Pontuação - ${questionnaire && questionnaire.name}`"
          :request-params="gridParams"
        >
          <template v-slot:filterZone>
            <div class="flex items-center">
              <vs-checkbox v-model="gridParams.section_grouped" color="primary"/>
              <vs-label :class="['text-sm select-none font-bold', {'text-primary': gridParams.section_grouped}]">{{ $t('agrupar-por-secao') }}</vs-label>
            </div>
          </template>
        </Grid>
      </div>
    </div>
  </vx-card>
</template>

<script>
import ReportService from '@/services/api/ReportService'
import SelectSuggestion from '@/components/SelectSuggestion.vue'
export default {
  components: { SelectSuggestion },

  data: () => ({
    service: null,
    questionnaire: null,
    gridParams: {
      section_grouped: true,
    }
  }),

  watch: {
    questionnaire(val) {
      this.updateGrid()
    },
  },

  computed: {
    validFilters() {
      return this.questionnaire && this.questionnaire.id
    }
  },

  methods: {
    updateGrid() {
      if (this.validFilters) {
        this.grid().fillCustomFilters(
          [{key:'questionnaire_id', value: this.questionnaire.id}]
        )
        this.grid().fetchGridData(false)
      }
    },
    grid() {
      return this.$refs['grid']
    }
  },

  beforeMount() {
    this.service = ReportService.build(this.$vs)
  }
}
</script>